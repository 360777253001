import useJwt from '@/auth/jwt/useJwt';

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    fetchReviews(ctx, data) {
      return useJwt.getReviews(data).then(response => response);
    },
    createReview(ctx, data) {
      return useJwt.createReview(data).then(response => response);
    },
    updateReview(ctx, id, data) {
      return useJwt.updateReview(id, data).then(response => response);
    },

  },
};
